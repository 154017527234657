<template>
  <iq-card>
    <template v:slot="body">
      <div class="common-part">
        <h3>Payment Type</h3>
        <section>

          <form>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <el-radio-group v-model="form.is_subscribe" v-removeAriaHidden @input="getForm">
                  <el-radio :label=1>One-time payment</el-radio>
                  <el-radio :label=2>Subscription</el-radio>
                </el-radio-group>
              </div>
              <div class="col-md-12 mb-3 d-flex" v-if="form.is_subscribe==2">
                <span>Interval:</span>
                <div class="ml-2 mt-1">
                  <el-radio-group v-model="number" @change="changeInterval">
                    <el-radio :label="1">Monthly</el-radio>
                    <el-radio :label="2">Weekly</el-radio>
                    <el-radio :label="3">Bi-weekly</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="col-md-12 mb-3" v-if="form.is_subscribe==2">
                <span>Start Date: {{(new Date().toISOString().slice(0, 10))|formatDateSub}}</span>
              </div>
              <div class="col-md-12 mb-3" v-if="form.is_subscribe==2">
                <label for="validationCustomUsername" class="appendAsterisk d-flex align-items-center">
                  <el-switch v-model="form.is_end_date" :active-value="1" :inactive-value="0"></el-switch>
                  <span class="ml-1" style="white-space: nowrap;">Has End Date </span>
                </label>
                <div v-if="form.is_end_date">
                  <div v-if="number!=1">
                    <section class="d-flex align-items-center number_mobile">
                      <span class="mr-1">Number of installment(s):</span>
                      <el-input-number size="small" :min="1" :precision='0' v-model="form.cycles" @change="changeCount">
                      </el-input-number>
                    </section>
                    <section class="mt-2">
                      <span>Final payment date:</span>
                      <span class="ml-1" style="white-space: nowrap;">{{form.end_date|formatDateSub}}</span>
                    </section>
                  </div>

                  <section v-else class="d-flex mt-3">
                    <el-input v-model="date" class="mr-3" disabled style="width: 30%;"></el-input>
                    <el-select v-model="month" placeholder="Month" class="mr-3" style="width: 30%;"
                      @change="changeMonth">
                      <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id">
                      </el-option>
                    </el-select>
                    <el-select v-model="year" placeholder="Year" style="width: 30%;" @change="changeMonth">
                      <el-option v-for="item in yearOptions" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </section>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </template>
  </iq-card>
</template>

<script>
  import moment from "moment";
  export default {

    data() {
      return {
        form: {
          is_subscribe: 1,
          start_date: new Date().toISOString().slice(0, 10),
          end_date: '',
          is_end_date: 0,
          cycles: 1,
          interval_count: 1,
          interval: 1
        },
        options: [
          { label: 'Jan', id: 1 },
          { label: 'Feb', id: 2 },
          { label: 'Mar', id: 3 },
          { label: 'Apr', id: 4 },
          { label: 'May', id: 5 },
          { label: 'Jun', id: 6 },
          { label: 'Jul', id: 7 },
          { label: 'Aug', id: 8 },
          { label: 'Sep', id: 9 },
          { label: 'Oct', id: 10 },
          { label: 'Nov', id: 11 },
          { label: 'Dec', id: 12 },
        ],
        number: 1,
        year: '',
        month: '',
        date: '',
        yearOptions: []
      };
    },


    created() {
      this.getForm()
      this.defaultDate()
      this.calculateDateMonth(1)
    },

    methods: {
      getForm() {
        this.$emit("getSubscribe", this.form);
      },

      defaultDate() {
        // 默认 年份数据
        let yearPlus = new Date().getFullYear()
        let currentMonth = new Date().getMonth()
        if (currentMonth == 11) {
        } else {
          this.yearOptions = [yearPlus]
        }
        for (var i = 0; i < 60; i++) {
          yearPlus++
          this.yearOptions.push(yearPlus)
        }
      },

      changeInterval() {
        if (this.number == 1) {
          this.form.cycles = 1
          this.calculateDateMonth(1)
        } else {
          this.form.cycles = 1
          this.changeCount()
        }
      },

      changeCount() {
        this.form.interval_count = this.number == 3 ? 2 : 1
        let quantity = this.form.cycles * this.form.interval_count
        this.form.interval = this.number == 1 ? 1 : 2
        this.calculateDateWeek(quantity)
      },

      changeMonth() {
        if ((this.year == new Date().getFullYear()) && (this.month <= (new Date().getMonth() + 1))) {
          this.calculateDateMonth(1)
          this.form.cycles = 1
          this.$message.warning('The date cannot be earlier than the current date')
        } else {
          const date = new Date(this.year, this.month, this.date);
          this.calculateMonthsBetweenDates(date)
        }
      },

      // 按月计算日期
      calculateDateMonth(quantity) {
        const currentDate = new Date();
        let newDate = moment(currentDate).add(quantity, 'months').format()
        this.form.end_date = moment(newDate).format("YYYY-MM-DD")
        this.year = new Date(newDate).getFullYear()
        this.month = new Date(newDate).getMonth() + 1
        this.date = new Date(newDate).getDate()
      },

      // 按周计算日期
      calculateDateWeek(quantity) {
        let date = new Date()
        let weeksInMs = quantity * 7 * 24 * 60 * 60 * 1000; // 将天数转换为毫秒
        let currentDate = new Date(date.getTime() + weeksInMs)
        this.form.end_date = moment(currentDate).format("YYYY-MM-DD")
      },

      // 按日期计数月数
      calculateMonthsBetweenDates(targetDate) {
        let currentDate = new Date()
        const monthsDifference =
          (targetDate.getFullYear() - currentDate.getFullYear()) * 12 +
          (targetDate.getMonth() - currentDate.getMonth());
        this.form.cycles = monthsDifference - 1
        this.calculateDateMonth(this.form.cycles)
      }
    },

  };
</script>
<style lang="scss" scoped>
  @media (max-width: 768px) {
    .number_mobile {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }

  .common-part {
    padding: 20px;
    color: #333;

    >h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }
  }

  ::v-deep .el-date-editor--date .el-input__inner,
  ::v-deep .el-select .el-input__inner,
  ::v-deep .el-input.is-disabled .el-input__inner {
    height: 45px;
    border-radius: 10px;
  }

  input[aria-hidden=true] {
    display: none !important;
  }
</style>